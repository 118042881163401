import { useEffect, useState } from "react";

// React DOM
import { useNavigate } from "react-router-dom";

// React Form
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// Axios
import Axios from "../api/axios";

// CSS styling
import "../styles/home.css";

// Social Media Icons and Images
import TwitterLogo from "../images/icons/TwitterLogo.png";
import FacebookLogo from "../images/icons/FacebookLogo.png";
import InstagramLogo from "../images/icons/InstagramLogo.png";
import LinkedInLogo from "../images/icons/LinkedInLogo.png";
import YoutubeLogo from "../images/icons/YoutubeLogo.png";
import globalImpactUN from "../images/icons/CloudxierUNlogo.jpeg";

// Helpers
import { currentYear } from "../helpers/currentYear";

// Components
import ContainerText from "./Container";
import Swal from "sweetalert2";

const Footer = () => {
  const navigate = useNavigate();

  const mediaQueryMobile = window.matchMedia("(max-width: 500px)");
  // const mediaQueryTab = useMediaQuery({
  //   query: "(min-width: 641px) and (max-width: 820px)",
  // });
  const mediaQueryTab = window.matchMedia(
    "(min-width:641px) and (max-width: 820px)"
  );

  const [isMobileSize, setIsMobileSize] = useState(false);
  const [isTabSize, setIsTabSize] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const handleEmailChange = (content) => {
    const value = content.target.value;
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (regex.test(value)) {
      document.getElementsByClassName(
        "footerNavigationSuccessIcon"
      )[0].style.visibility = "visible";
    } else {
      document.getElementsByClassName(
        "footerNavigationSuccessIcon"
      )[0].style.visibility = "hidden";
    }
  };

  const onSubmit = (value) => {
    Axios.post("/subscriber", {
      emailSubscriber: value.emailSubscriber,
    })
      .then((res) => {
        Swal.fire({
          customClass: {
            title: "subscriberTitle",
            htmlContainer: "subscriberText",
            image: "subscriberImage",
          },
          title: "Thank you for joining us!",
          text: "You have been subscribed to our newsletter. Stay tune!",
          imageUrl:
            "https://media.discordapp.net/attachments/796711355876245534/961816357949820968/Victory.png",
          imageAlt: "Custom image",
          showConfirmButton: false,
          showCloseButton: true,
          width: "53em",
        });
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire({
          customClass: {
            title: "subscriberTitle",
            htmlContainer: "subscriberText",
            image: "subscriberImage",
          },
          title: "email has been registered",
          text: "you have been subscribed to our newsletter ",
          imageUrl:
            "https://media.discordapp.net/attachments/796711355876245534/961884635787317328/3298067.jpg?width=676&height=676",
          imageAlt: "Custom image",
          showConfirmButton: false,
          showCloseButton: true,
          width: "53em",
        });
      });
  };

  const handleDetectWindowSize = (mediaQuery, tab) => {
    if (tab === "mobile") {
      setIsTabSize(false);
      setIsMobileSize(mediaQuery.matches);
    } else {
      setIsMobileSize(false);
      setIsTabSize(mediaQuery.matches);
    }
  };

  useEffect(() => {
    if (mediaQueryMobile && mediaQueryMobile.matches) {
      handleDetectWindowSize(mediaQueryMobile, "mobile");
    }
  }, [mediaQueryMobile]);

  useEffect(() => {
    if (mediaQueryTab && mediaQueryTab.matches) {
      handleDetectWindowSize(mediaQueryTab, "tab");
    }
  }, [mediaQueryTab]);

  return (
    <div
      id="footer"
      style={{
        width: "100%",
        height: "auto",
        margin: 0,
        padding: 0,
        overflowX: "hidden",
      }}
    >
      <div className="footerContainer">
        <div className="footerLine">
          <hr />
        </div>
        <div className="footerUnGlobalImpact">
          <img src={globalImpactUN} alt="UN Global Impact" />
          <p>
            Institute for Sustainability and Agility (ISA) has participated as a
            Member of United Nations Global Impact (UNGC) since 2021
          </p>
        </div>
        <div className="footerNavigationContainer">
          <ContainerText
            containerPadding={0}
            props={
              <div>
                <div className="backToTop">
                  <i className="fas fa-angle-up"></i>
                  <a href="#">Back to top</a>
                </div>
                <div className="footerNavigationMain">
                  <div className="footerNavigationMainList">
                    <ul className="list-group list-group-vertical">
                      <li
                        className="list-group-item bg-transparent border-0"
                        onClick={() => navigate(`/`)}
                      >
                        <a>Home</a>
                      </li>
                      <li
                        className="list-group-item bg-transparent border-0"
                        onClick={() => navigate(`/Expertise`)}
                      >
                        ISA Consulting
                      </li>
                      <li
                        className="list-group-item bg-transparent border-0"
                        onClick={() => navigate(`/Clients`)}
                      >
                        <a>Our Clients</a>
                      </li>
                      <li className="list-group-item bg-transparent border-0">
                        <a
                          href="https://www.cloudxier.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          IT Consultancy Services
                        </a>
                      </li>
                      <li
                        className="list-group-item bg-transparent border-0"
                        onClick={() => navigate(`/Contact`)}
                      >
                        Contact Us
                      </li>
                    </ul>
                    <ul className="list-group list-group-vertical">
                      <li
                        className="list-group-item bg-transparent border-0"
                        style={{ fontWeight: "700" }}
                      >
                        About us
                      </li>
                      <li
                        className="list-group-item bg-transparent border-0"
                        onClick={() => navigate(`/About/Company`)}
                      >
                        Our Company
                      </li>
                      <li
                        className="list-group-item bg-transparent border-0"
                        onClick={() => navigate(`/About/TeamMembers`)}
                      >
                        Team Members
                      </li>
                      <li className="list-group-item bg-transparent border-0 d-none">
                        Resources
                      </li>
                      <li className="list-group-item bg-transparent border-0 d-none">
                        Events
                      </li>
                      <li className="list-group-item bg-transparent border-0 d-none">
                        ISA Learning
                      </li>
                      <li className="list-group-item bg-transparent border-0 d-none">
                        E-Book
                      </li>
                      <li className="list-group-item bg-transparent border-0 d-none">
                        Community
                      </li>
                      <li className="list-group-item bg-transparent border-0 d-none">
                        Articles
                      </li>
                    </ul>
                    <ul className="list-group list-group-vertical">
                      <li className="list-group-item bg-transparent border-0 d-none">
                        Careers
                      </li>
                      <li className="list-group-item bg-transparent border-0 d-none">
                        FAQ
                      </li>
                    </ul>
                  </div>
                  <div className="footerNavigationSubscribe">
                    <p className="footerNavigationSubscribeMainText">
                      Stay tune on the latest insights
                    </p>
                    <p className="footerNavigationSubscribeSubText">
                      Subscribe to our newsletter
                    </p>
                    <div className="footerNavigationSubscribeForm">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="footerNavigationSubscribeInput">
                          <input
                            type="email"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            name="emailSubscriber"
                            placeholder="Enter your email address"
                            {...register("emailSubscriber", {
                              required: "please provide your email address",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message:
                                  "email format invalid, please try again..",
                              },
                              onChange: (content) => handleEmailChange(content),
                            })}
                          />
                          {/* <span className="footerNavigationSuccessIcon">
                            <img
                              src="https://cdn.discordapp.com/attachments/796711355876245534/960475154796904468/unknown.png"
                              alt="check icon"
                            />
                          </span> */}
                          <div className="footerNavigationError">
                            <ErrorMessage
                              errors={errors}
                              name="emailSubscriber"
                              render={({ message }) => <p>{message}</p>}
                            />
                          </div>
                          <div className="footerNavigationAgreement bigScreen">
                            <p>
                              By clicking the "Submit" button, you are agreeing
                              to the ISA’s{" "}
                              <span
                                onClick={() =>
                                  navigate(`/Policy/PrivacyStatement`)
                                }
                              >
                                privacy policy
                              </span>
                            </p>
                          </div>
                        </div>
                        <input
                          type="submit"
                          value="Submit"
                          className="btn btn-warning footerSubmit"
                        />
                        <div className="footerNavigationAgreement smallScreen">
                          <p>
                            By clicking the "Submit" button, you are agreeing to
                            the ISA’s{" "}
                            <span
                              onClick={() =>
                                navigate(`/Policy/PrivacyStatement`)
                              }
                            >
                              privacy policy
                            </span>
                          </p>
                        </div>
                      </form>
                    </div>
                    <div className="footerNavigationSubscribeSocialMedia">
                      <a
                        href="https://twitter.com/isa_company"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={TwitterLogo} alt="" />
                      </a>
                      <a
                        href="https://www.facebook.com/isa.sustainability"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={FacebookLogo} alt="" />
                      </a>
                      <a
                        href="https://www.instagram.com/isa.sustainability"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={InstagramLogo} alt="" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/institute-for-sustainability-and-agility/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={LinkedInLogo} alt="" />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCWsQCHf9PodPl-_V_GaYorg/featured"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={YoutubeLogo} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <div className="footerCopyright">
          <ContainerText
            props={
              <div className="footerCopyrightContainer">
                <div>
                  <p>
                    © {currentYear()} Institute for Sustainability and Agility
                    (ISA), PT Cendekia Edukasi Citra Tritunggal.
                  </p>
                </div>
                <div>
                  <ul
                    className="list-group list-group-horizontal bigScreen"
                    style={{ cursor: "pointer" }}
                  >
                    <li
                      className="list-group-item bg-transparent border-0"
                      onClick={() => navigate(`/Policy/PrivacyStatement`)}
                    >
                      Privacy Policy
                    </li>
                    <li className="list-group-item bg-transparent border-0 d-none">
                      Terms
                    </li>
                    <li
                      className="list-group-item bg-transparent border-0"
                      onClick={() => navigate(`/Policy/Cookies`)}
                    >
                      Cookie Policy
                    </li>
                    <li
                      className="list-group-item bg-transparent border-0"
                      onClick={() => navigate(`/Policy/Sustainability`)}
                    >
                      Sustainability Policy
                    </li>
                  </ul>
                  <ul
                    className="list-group list-group-vertical smallScreen"
                    style={{ cursor: "pointer" }}
                  >
                    <li
                      className="list-group-item bg-transparent border-0"
                      onClick={() => navigate(`/Policy/PrivacyStatement`)}
                    >
                      Privacy Policy
                    </li>
                    <li className="list-group-item bg-transparent border-0 d-none">
                      Terms
                    </li>
                    <li
                      className="list-group-item bg-transparent border-0"
                      onClick={() => navigate(`/Policy/Cookies`)}
                    >
                      Cookie Policy
                    </li>
                    <li
                      className="list-group-item bg-transparent border-0"
                      onClick={() => navigate(`/Policy/Sustainability`)}
                    >
                      Sustainability Policy
                    </li>
                  </ul>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
