import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// CSS styling
import "../../styles/activitiesPublic.css";
import "../../styles/clientBook.css";

// moment js
import moment from "moment";

// HTML react parser
import parse from "html-react-parser";

// image dummy
import NoWorkshopAvailable from "../../images/icons/NoWorkshopAvailable.png";

// Components
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import ContainerText from "../../components/Container.js";

// apis
import { fetchBooks } from "../../api/books.js";

const Books = () => {
  const navigate = useNavigate();

  const mediaQueryMobile = window.matchMedia("(max-width: 680px)");

  const [isMobileSize, setIsMobileSize] = useState(false);

  const [books, setBooks] = useState([]);

  const handleDetectWindowSize = (mediaQuery, tab) => {
    if (tab === "mobile") {
      setIsMobileSize(mediaQuery.matches);
    }
  };

  const handleFetchBooks = async () => {
    const result = await fetchBooks();
    if (result && result.data) {
      setBooks(result.data);
    }
  };

  useEffect(() => {
    if (mediaQueryMobile) {
      handleDetectWindowSize(mediaQueryMobile, "mobile");
    }
  }, [mediaQueryMobile]);

  useEffect(() => {
    handleFetchBooks();
  }, []);

  return (
    <div>
      <Header />
      <div className="activityPublicContainer">
        <ContainerText
          props={
            <div
              className="activityPublicDetailCardContainerDesc"
              style={{ margin: "25px" }}
            >
              <div style={{ marginTop: "5em", marginBottom: "4em" }}>
                <div
                  style={{
                    color: "#FF9F1E",
                    fontSize: isMobileSize ? "35px" : "55px",
                    fontWeight: "700",
                  }}
                >
                  Books
                </div>
              </div>
              {books && Array.isArray(books) && books.length > 0 ? (
                books.map((book) => {
                  const { id, img_url, title, description, published_on } =
                    book || {};
                  return (
                    <div
                      key={id}
                      className="eachClientBook"
                      onClick={() => navigate(`/Books/${id}`)}
                    >
                      <div className="eachClientBookImageContainer">
                        <img
                          // src={image_book_dummy}
                          src={img_url}
                          alt={`book-${title}`}
                        />
                      </div>
                      <div className="eachClientBookTitleDescContainer">
                        <div className="eachClientBookTitleStyle">{title}</div>
                        <div className="eachClientBookPublishedDateStyle">
                          {`Published on: ${
                            published_on
                              ? moment(published_on).format("DD/MM/YYYY")
                              : "-"
                          }`}
                        </div>
                        <div className="eachClientBookDescriptionStyle">
                          {parse(description)}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingBottom: "3em",
                  }}
                >
                  <div style={{ marginBottom: "3em" }}>
                    <img
                      src={NoWorkshopAvailable}
                      alt="Data not found icon"
                      width={200}
                    />
                  </div>
                  <h4
                    style={{
                      color: "#000000",
                    }}
                  >
                    No book available at this moment. Stay tune!
                  </h4>
                </div>
              )}
            </div>
          }
        />
      </div>
      <Footer />
    </div>
  );
};

export default Books;
