import React, { useEffect, useState } from "react";

// React DOM
import { useParams, useNavigate } from "react-router-dom";

// Font Awesome React
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

// React lottie
import Lottie from "react-lotties";
import PaperPlane from "../../lotties/paperplane.json";

// Icons
import FilterLogo from "../../images/icons/FilterLogo.png";
import NotepadPencil from "../../images/icons/NotepadPencil.png";
import ListEmpty from "../../images/icons/ListEmpty.png";
import WhiteTrash from "../../images/icons/WhiteTrash.png";
import WhitePlus from "../../images/icons/WhitePlus.png";
import EllipseDraft from "../../images/icons/admin/EllipseDraft.png";
import EllipseHidden from "../../images/icons/admin/EllipseHidden.png";
import EllipsePublished from "../../images/icons/admin/EllipsePublished.png";
import StatusAlert from "../../images/icons/admin/StatusAlert.png";
import TrashBin from "../../images/icons/admin/trashBin.png";

// Axios and API
import { getNewsById } from "../../api/getNewsById";
import { getCategories } from "../../api/getCategories";
import { addNews } from "../../api/addNews";
import { editNews } from "../../api/editNews";
import { deleteNews } from "../../api/deleteNews";
import { fetchNews } from "../../api/fetchNews";
import { addCategory } from "../../api/addCategory";
import { deleteCategoryName } from "../../api/deleteCategory";
import { deleteMultipleNews } from "../../api/deleteMultipleNews";
import { fetchWorkshops } from "../../api/fetchWorkshops";
import { fetchTestimonials } from "../../api/fetchTestimonials";
import { fetchActivities } from "../../api/fetchActivities";
import { fetchFounderStories } from "../../api/founderStories";
import { fetchBooks } from "../../api/books";

// React Form
import { useForm } from "react-hook-form";

// Sweet Alert v2
import Swal from "sweetalert2";

// React Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// CSS Styling
import "../../styles/admin.css";

// Helpers
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter";

// Components
import PostForm from "../../components/PostForm";
import BlogButton from "../../components/BlogButton";
import AdminNavbar from "../../components/AdminNavbar";
import AdminNavigation from "../../components/AdminNavigation";
import PostAlert from "../../components/PostAlert";
import IsaAcademyForm from "../ISA-Academy-Form/IsaAcademyForm";
import Activities from "../Activities/Activities";
import FoundersStory from "../FoundersStory/FoundersStory";
import Book from "../Books/Book";

const AdminPage = () => {
  const [customDate, setCustomDate] = useState(new Date());
  const [news, setNews] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [statusName, setStatusName] = useState("Draft, Not published");
  const [editForm, setEditForm] = useState(null);
  const [newsHandler, setNewsHandler] = useState(false);
  const [allStatus, setAllStatus] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState([]);
  const [allCategories, setAllCategories] = useState(false);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [checkedDate, setCheckedDate] = useState([]);
  const [allNews, setAllNews] = useState(false);
  const [checkedNews, setCheckedNews] = useState([]);
  const [isFilterButton, setFilterButton] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [postName, setPostName] = useState(null);
  const [showAlertWorkshop, setShowAlertWorkshop] = useState(false);
  const [workshopAlertMsg, setWorkshopAlertMsg] = useState("");
  const [workshops, setWorkshops] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [activities, setActivities] = useState([]);
  const [foundersStories, setFoundersStories] = useState([]);
  const [thumbnailImageFailedToUploadMsg, setThumbnailImageFailedToUploadMsg] =
    useState("");
  // const [contentImageFailedToUploadMsg, setContentImageFailedToUploadMsg] =
  //   useState("");

  const [books, setBooks] = useState([]);

  const { register, handleSubmit } = useForm({ mode: "all" });
  const { params } = useParams();
  const { crud } = useParams();
  const { editId } = useParams();
  const navigate = useNavigate();

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: PaperPlane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Alert for status change on post
  const handleStatusAlert = () => {
    const element = document.querySelector(".postStatusAlert");
    element.classList.add("animate__animated", "animate__slideInDown");
    document.getElementsByClassName("postStatusAlert")[0].style.opacity = 1;

    setTimeout(() => {
      document.getElementsByClassName("postStatusAlert")[0].style.opacity = 0;
    }, 5000);
  };

  // Delete news in post list by ID
  const deleteNewsById = (id) => {
    Swal.fire({
      customClass: {
        cancelButton: "cancelPostButton",
        confirmButton: "confirmPostButton",
        title: "cancelPostButtonTitle",
      },
      title: "are you sure you want to delete this? ",
      text: "Once confirmed, you can’t undo this.",
      padding: "3em",
      showCancelButton: true,
      confirmButtonColor: "white",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    })
      .then((res) => {
        if (res.isConfirmed) {
          deleteNews(id)
            .then((res) => {
              setPostName(res.data);
              setShowAlert(true);
              setNewsHandler(!newsHandler);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setTimeout(() => {
                setShowAlert(false);
              }, 3000);
            });
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Delete multiple news by query ID
  const deleteSelectedNews = () => {
    Swal.fire({
      customClass: {
        cancelButton: "cancelPostButton",
        confirmButton: "confirmPostButton",
        title: "cancelPostButtonTitle",
      },
      title: "are you sure you want to delete this? ",
      text: "Once confirmed, you can’t undo this.",
      padding: "3em",
      showCancelButton: true,
      confirmButtonColor: "white",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    })
      .then((res) => {
        if (res.isConfirmed) {
          deleteMultipleNews(checkedNews)
            .then((res) => {
              setPostName("News has been deleted!");
              setShowAlert(true);
              setNewsHandler(!newsHandler);
              setCheckedNews([]);
            })
            .catch((err) => {
              console.log(err.response);
            })
            .finally(() => {
              setTimeout(() => {
                setShowAlert(false);
              }, 3000);
            });
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // Change status on post
  const changeStatus = (name) => {
    setStatusName(name);
    handleStatusAlert();
  };

  // Change status on post without alert
  const changeStatusWithoutAlert = (name) => {
    setStatusName(name);
  };

  // Change display status name
  const handleStatusName = (value) => {
    if (value === "Draft, Not published") return (value = "Draft");
    return value;
  };

  // Change image flag on status
  const handleStatusImage = (value) => {
    if (value === "Hidden") {
      return EllipseHidden;
    } else if (value === "Draft, Not published") {
      return EllipseDraft;
    } else {
      return EllipsePublished;
    }
  };

  const handleSave = async ({
    title,
    writersName,
    shortDescription,
    thumbnailImageToSave,
    // contentImageToSave,
    categoryVal,
    statusName,
    content,
  }) => {
    const data = new FormData();
    data.append("title", capitalizeFirstLetter(title));
    data.append("written_by", writersName);
    data.append("description", capitalizeFirstLetter(shortDescription));
    if (!editId) {
      console.log(thumbnailImageToSave, "thumbnailImageToSave <<");

      data.append("thumbnailImage", thumbnailImageToSave);
      // data.append("contentImage", contentImageToSave);
    } else {
      if (thumbnailImageToSave) {
        data.append("thumbnailImage", thumbnailImageToSave);
      }
      // if (contentImageToSave) {
      //   data.append("contentImage", contentImageToSave);
      // }
    }
    data.append("CategoryId", categoryVal.value);
    data.append("status", statusName);
    data.append("contentType", categoryVal.label);
    data.append("htmlContent", content);
    try {
      const result = editId
        ? await editNews(editId, data)
        : await addNews(data);
      if (result) {
        Swal.fire({
          customClass: {
            confirmButton: "publishButton",
            title: "publishTitle",
            popup: "publishPopup",
          },
          showCloseButton: true,
          confirmButtonColor: "#1F81DC",
          title: "Success!",
          text: editId
            ? "This post has been succesfully edited"
            : "This post has been succesfully created",
          confirmButtonText: "Okay, got it!",
        });
        navigate(`/admin/post`);
        fetchAllNews();
      }
    } catch (err) {
      const {
        errorName = "",
        thumbnailImage = "",
        contentImage = "",
        errorMsg,
        message,
      } = err.response.data;
      if (
        message &&
        (message === "Unauthorized" ||
          message === "Unauthenticated" ||
          message === "TokenExpiredError")
      ) {
        Swal.fire({
          position: "top-end",
          text: "Token expired, please re-login",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          width: "450px",
        });
        setLoading(false);
        localStorage.removeItem("isVerified");
        localStorage.removeItem("access_token");
        localStorage.removeItem("email");
        navigate("/signIn");
      } else if (errorName && errorName === "Bad request") {
        if (thumbnailImage) {
          setThumbnailImageFailedToUploadMsg(errorMsg);
        } else {
          setThumbnailImageFailedToUploadMsg("");
        }
        // if (contentImage) {
        //   setContentImageFailedToUploadMsg(errorMsg);
        // } else {
        //   setContentImageFailedToUploadMsg("");
        // }
      } else if (errorName && errorName === "File too large (Max: 1mb)") {
        if (thumbnailImage) {
          setThumbnailImageFailedToUploadMsg(errorName);
        } else {
          setThumbnailImageFailedToUploadMsg("");
        }
        // if (contentImage) {
        //   setContentImageFailedToUploadMsg(errorName);
        // } else {
        //   setContentImageFailedToUploadMsg("");
        // }
      } else {
        Swal.fire({
          position: "top-end",
          text: "Please fill all required elements",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          width: "450px",
        });
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Adding news category in post list
  const submitCategory = async (value) => {
    try {
      await addCategory({
        name: value.name,
      });
      setNewsHandler(!newsHandler);
      setPostName(`${value.name} has been added`);
      setShowAlert(true);
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  // Change color indicator on status
  const changeStatusColor = (value) => {
    if (value === "Published") {
      return "#11998E";
    } else if (value === "Draft, Not published") {
      return "#B15927";
    } else {
      return "#798692";
    }
  };

  // Open popup with overlay
  const openModal = (className) => {
    document
      .getElementsByClassName(className)[0]
      .scrollIntoView({ block: "center" });
    document.getElementsByClassName("overlayFilterButton")[0].style.visibility =
      "unset";
    document.getElementsByClassName("overlayFilterButton")[0].style.opacity = 1;
    document.getElementsByClassName(className)[0].style.visibility = "unset";

    if (className === "ButtonListFilter") {
      setFilterButton(true);
    }
  };

  // Close popup with overlay
  const closeModal = (className) => {
    document.getElementsByClassName("overlayFilterButton")[0].style.visibility =
      "hidden";
    document.getElementsByClassName("overlayFilterButton")[0].style.opacity = 0;
    document.getElementsByClassName(className)[0].style.visibility = "hidden";

    if (className === "ButtonListFilter") {
      setFilterButton(false);
    }
  };

  // Filtering for status
  const checkSelectedStatus = (contentType) => {
    if (contentType === "All status") {
      if (!allStatus) {
        setAllStatus(!allStatus);
        setCheckedStatus(["Published", "Draft, Not published", "Hidden"]);
      } else {
        setAllStatus(!allStatus);
        setCheckedStatus([]);
      }
    } else {
      if (checkedStatus.indexOf(contentType) < 0) {
        setCheckedStatus([...checkedStatus, contentType]); // push element
      } else {
        setCheckedStatus(
          checkedStatus.filter((content) => content !== contentType)
        ); // remove element
        setAllStatus(false);
      }
    }
  };

  // Filtering for category
  const checkSelectedCategory = (contentType) => {
    if (contentType === "All category") {
      if (!allCategories) {
        setAllCategories(!allCategories);
        setCheckedCategories(categories.map((category) => category.name));
      } else {
        setAllCategories(!allCategories);
        setCheckedCategories([]);
      }
    } else {
      if (checkedCategories.indexOf(contentType) < 0) {
        setCheckedCategories([...checkedCategories, contentType]); // push element
      } else {
        setCheckedCategories(
          checkedCategories.filter((content) => content !== contentType)
        ); // remove element
        setAllCategories(false);
      }
    }
  };

  // Filtering for time
  const checkSelectedTime = (contentType) => {
    if (checkedDate.indexOf(contentType) < 0) {
      setCheckedDate([...checkedDate, contentType]); // push element
    } else {
      setCheckedDate(checkedDate.filter((content) => content !== contentType)); // remove element
    }
  };

  // Select and Delete multiple news
  const checkSelectedNews = (newsSelected) => {
    if (newsSelected === "All news") {
      if (!allNews) {
        setAllNews(!allNews);
        setCheckedNews(news.map((news) => news.id));
      } else {
        setAllNews(!allNews);
        setCheckedNews([]);
      }
    } else {
      if (checkedNews.indexOf(newsSelected) < 0) {
        setCheckedNews([...checkedNews, parseInt(newsSelected)]); // push element
      } else {
        setCheckedNews(checkedNews.filter((news) => news !== newsSelected));
      }
    }
  };

  // Reset filter
  const resetFilter = () => {
    setAllStatus(false);
    setAllCategories(false);
    setCheckedCategories([]);
    setCheckedStatus([]);
    setCheckedDate([]);
  };

  // Delete category on post list
  const deleteCategory = (id) => {
    Swal.fire({
      customClass: {
        cancelButton: "cancelPostButton",
        confirmButton: "confirmPostButton",
        title: "cancelPostButtonTitle",
      },
      title: "are you sure you want to delete this? ",
      text: "Once confirmed, you can’t undo this.",
      padding: "3em",
      showCancelButton: true,
      confirmButtonColor: "white",
      cancelButtonColor: "#3085d6",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    })
      .then((res) => {
        if (res.isConfirmed) {
          deleteCategoryName(id)
            .then((res) => {
              setNewsHandler(!newsHandler);
              setPostName(res.data);
              setShowAlert(true);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setTimeout(() => {
                setShowAlert(false);
              }, 3000);
            });
        } else {
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAllNews = async () => {
    setLoading(true);
    try {
      const result = await fetchNews(
        checkedStatus,
        checkedCategories,
        checkedDate,
        customDate
      );
      return setNews(result.data);
    } catch (err) {
      Swal.fire(
        "Error!",
        "Posts not found, please check the server! (500)",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  // Fetching all post on post list
  useEffect(() => {
    fetchAllNews();
  }, [newsHandler, checkedStatus, checkedCategories, checkedDate, customDate]);

  // Fetching all categories
  useEffect(() => {
    const fetchCategories = async () => {
      const result = await getCategories();
      setCategories(result.data);
    };
    fetchCategories();
  }, [newsHandler]);

  const fetchAllWorkshops = async () => {
    setLoading(true);
    const response = await fetchWorkshops({
      sortById: "DESC",
      sortByWorkshopTitle: null,
      // tags,
      typeOfWorkshop: null,
      methodOfLearning: null,
    });
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      const finalWorkshops =
        data && Array.isArray(data) && data.length > 0
          ? data.map((workshop) => {
              const {
                id,
                updatedAt,
                workshop_title,
                User: { email } = {},
                Schedules = [],
              } = workshop || {};
              const objWorkshop = {
                id,
                lastModified: updatedAt,
                schedule: Schedules.length,
                postedBy: email,
                workshopTitle: workshop_title,
              };
              return objWorkshop;
            })
          : [];
      setWorkshops(finalWorkshops);
      setLoading(false);
    }
  };

  const fetchAllTestimonials = async () => {
    setLoading(true);
    const response = await fetchTestimonials("DESC");
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      let finalTestimonials = [];
      if (data && Array.isArray(data) && data.length > 0) {
        const arrTestimonials = data.sort(
          (a, b) => parseFloat(b.id) - parseFloat(a.id)
        );
        finalTestimonials =
          arrTestimonials &&
          Array.isArray(arrTestimonials) &&
          arrTestimonials.length > 0 &&
          arrTestimonials.map((testimonial) => {
            const {
              id,
              updatedAt,
              participant_name,
              User: { email } = {},
              workshop_title,
            } = testimonial || {};
            const objTestimonial = {
              id,
              lastModified: updatedAt,
              name: participant_name,
              postedBy: email,
              workshopTitle: workshop_title,
            };
            return objTestimonial;
          });
      }
      setTestimonials(finalTestimonials);
      setLoading(false);
    }
  };

  const fetchAllActivities = async () => {
    setLoading(true);
    const response = await fetchActivities();
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      let finalAcitivities = [];
      if (data && Array.isArray(data) && data.length > 0) {
        const arrActivities = data.sort(
          (a, b) => parseFloat(b.id) - parseFloat(a.id)
        );
        finalAcitivities =
          arrActivities &&
          Array.isArray(arrActivities) &&
          arrActivities.length > 0 &&
          arrActivities.map((activity) => {
            const { id, updatedAt, User, title, activityType } = activity || {};
            const objActivity = {
              id,
              lastModified: updatedAt,
              postedBy: (User && User.email) || "-",
              title,
              type: activityType,
            };
            return objActivity;
          });
        setActivities(finalAcitivities);
      }
    }
    setLoading(false);
  };

  const fetchAllFoundersStories = async () => {
    setLoading(true);
    const response = await fetchFounderStories();
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      let finalFoundersStories = [];
      if (data && Array.isArray(data) && data.length > 0) {
        const arrFoundersStories = data.sort(
          (a, b) => parseFloat(b.id) - parseFloat(a.id)
        );
        finalFoundersStories =
          arrFoundersStories &&
          Array.isArray(arrFoundersStories) &&
          arrFoundersStories.length > 0 &&
          arrFoundersStories.map((foundersStory) => {
            const {
              id,
              updatedAt,
              User: { email } = {},
              title,
            } = foundersStory || {};
            const objFoundersStory = {
              id,
              lastModified: updatedAt,
              postedBy: email,
              title,
            };
            return objFoundersStory;
          });
        setFoundersStories(finalFoundersStories);
      }
    }
    setLoading(false);
  };

  const fetchAllBooks = async () => {
    setLoading(true);
    const response = await fetchBooks();
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      let finalBooks = [];
      if (data && Array.isArray(data) && data.length > 0) {
        const arrBooks = data.sort(
          (a, b) => parseFloat(b.id) - parseFloat(a.id)
        );
        finalBooks =
          arrBooks &&
          Array.isArray(arrBooks) &&
          arrBooks.length > 0 &&
          arrBooks.map((book) => {
            const { id, updatedAt, author: { email } = {}, title } = book || {};
            const objBook = {
              id,
              lastModified: updatedAt,
              postedBy: email,
              title,
            };
            return objBook;
          });
        setBooks(finalBooks);
      }
    }
    setLoading(false);
  };

  // Fetch edit data on edit section
  useEffect(() => {
    if (editId && params === "post") {
      const fetchEditData = async () => {
        setLoading(true);
        const result = await getNewsById(editId);
        if (result && result.status === 200 && result.data) {
          const { data } = result;
          setEditForm(data);
          changeStatusWithoutAlert(data.status);
        }
        setLoading(false);
      };
      fetchEditData();
    }
  }, [editId, newsHandler]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlertWorkshop(false);
    }, 3000);
  }, [showAlertWorkshop]);

  useEffect(() => {
    fetchAllWorkshops();
    fetchAllTestimonials();
    fetchAllActivities();
    fetchAllFoundersStories();
  }, []);

  // Fetch edit data on edit section
  useEffect(() => {
    if (editId && params === "post") {
      const fetchEditData = async () => {
        setLoading(true);
        const result = await getNewsById(editId);
        if (result && result.status === 200 && result.data) {
          const { data } = result;
          setEditForm(data);
          changeStatusWithoutAlert(data.status);
        }
        setLoading(false);
      };
      fetchEditData();
    }
  }, [editId, newsHandler]);

  useEffect(() => {
    if (!params) {
      navigate("/admin/post");
    }
  }, [params]);

  useEffect(() => {
    if (params === "books") {
      fetchAllBooks();
    }
  }, [params]);

  return (
    <div>
      <div id="admin">
        <AdminNavbar />
        <AdminNavigation
          customValue={news}
          workshops={workshops}
          testimonials={testimonials}
          params={params}
        />
        <PostAlert show={showAlert} name={postName} />
        <div className="adminContainer">
          {showAlertWorkshop ? (
            <PostAlert
              show={showAlertWorkshop}
              name={workshopAlertMsg}
              isWorkshop={true}
            />
          ) : (
            <div className="postStatusAlert">
              <div className="postStatusAlertLeftColor"></div>
              <div className="postStatusAlertText">
                <span>
                  <img src={StatusAlert} alt="Status Alert" /> You have
                  successfully changed this post status to{" "}
                  <strong>“{statusName}”</strong>
                </span>
              </div>
            </div>
          )}
          {params === "post" && !crud ? (
            <div>
              <div className="adminPostList">
                <div className="adminPostListTitleContainer">
                  <div className="adminPostListTitle">
                    <p>Posts</p>
                  </div>
                  <div className="adminPostListFunction">
                    <div className="adminPostListEachFunction">
                      <BlogButton
                        onClickButton={() => openModal("ButtonListFilter")}
                        icon={FilterLogo}
                        iconDesc="Filter"
                        text="Filter"
                        buttonPadding="0.4em 1.8em 0.4em 1em"
                      />
                      <div
                        className="overlayFilterButton"
                        onClick={() =>
                          isFilterButton
                            ? closeModal("ButtonListFilter")
                            : closeModal("CategoryPostButton")
                        }
                      ></div>
                      <div className="ButtonListFilter">
                        <div className="ButtonListContainer">
                          <div className="ButtonFilterTitle">
                            <p>By status</p>
                            <div>
                              <span
                                className="resetFilterButton"
                                onClick={resetFilter}
                              >
                                Reset
                              </span>
                              <span
                                className="saveFilterButton"
                                onClick={() => closeModal("ButtonListFilter")}
                              >
                                Save
                              </span>
                            </div>
                          </div>
                          <hr />
                          <div className="ButtonFunction">
                            <div className="ButtonFilterList">
                              <input
                                onChange={() =>
                                  checkSelectedStatus("All status")
                                }
                                checked={allStatus}
                                type="checkbox"
                              />
                              <span> All status</span>
                              <br />
                            </div>
                            <div className="ButtonFilterList">
                              <input
                                onChange={() =>
                                  checkSelectedStatus("Published")
                                }
                                checked={
                                  checkedStatus.indexOf("Published") > -1
                                }
                                type="checkbox"
                              />
                              <span> Published</span>
                              <br />
                            </div>
                            <div className="ButtonFilterList">
                              <input
                                onChange={() =>
                                  checkSelectedStatus("Draft, Not published")
                                }
                                checked={
                                  checkedStatus.indexOf(
                                    "Draft, Not published"
                                  ) > -1
                                }
                                type="checkbox"
                              />
                              <span> Draft, not published</span>
                              <br />
                            </div>
                            <div className="ButtonFilterList">
                              <input
                                onChange={() => checkSelectedStatus("Hidden")}
                                checked={checkedStatus.indexOf("Hidden") > -1}
                                type="checkbox"
                              />
                              <span> Hidden</span>
                              <br />
                            </div>
                          </div>
                          <div className="ButtonFilterTitle customMarginTop">
                            <p>By category</p>
                          </div>
                          <hr />
                          <div className="ButtonFunction categoryAdminFilter">
                            <div className="ButtonFilterList">
                              <input
                                onChange={() =>
                                  checkSelectedCategory("All category")
                                }
                                checked={allCategories}
                                type="checkbox"
                              />
                              <span> All category</span>
                              <br />
                            </div>
                            {categories.map((category) => (
                              <div
                                className="ButtonFilterList"
                                key={category.id}
                              >
                                <input
                                  onChange={() =>
                                    checkSelectedCategory(category.name)
                                  }
                                  checked={
                                    checkedCategories.indexOf(category.name) >
                                    -1
                                  }
                                  type="checkbox"
                                />
                                <span> {category.name}</span>
                                <br />
                              </div>
                            ))}
                          </div>
                          <div className="ButtonFilterTitle customMarginTop">
                            <p>By date & time</p>
                          </div>
                          <hr />
                          <div className="ButtonFunction">
                            <div className="ButtonFilterList">
                              <input
                                onChange={() => checkSelectedTime("Today")}
                                checked={checkedDate.indexOf("Today") > -1}
                                type="checkbox"
                              />
                              <span> Today</span>
                              <br />
                            </div>
                            <div className="ButtonFilterList">
                              <input
                                onChange={() => checkSelectedTime("Last month")}
                                checked={checkedDate.indexOf("Last month") > -1}
                                type="checkbox"
                              />
                              <span> Last month</span>
                              <br />
                            </div>
                            <div className="ButtonFilterList">
                              <input
                                onChange={() => checkSelectedTime("Last week")}
                                checked={checkedDate.indexOf("Last week") > -1}
                                type="checkbox"
                              />
                              <span> Last week</span>
                              <br />
                            </div>
                            <div className="ButtonFilterList">
                              <input
                                onChange={() =>
                                  checkSelectedTime("Custom date")
                                }
                                checked={
                                  checkedDate.indexOf("Custom date") > -1
                                }
                                type="checkbox"
                              />
                              <span> Custom date</span>
                              <br />
                              {checkedDate.indexOf("Custom date") > -1 ? (
                                <DatePicker
                                  selected={customDate}
                                  onChange={(date) => setCustomDate(date)}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {checkedNews.length === 0 ? (
                      <div className="adminPostListEachFunction addPostButton">
                        <button onClick={() => navigate("/admin/post/add")}>
                          Add New
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="adminPostTable">
                <div className="adminPostTableContainer">
                  <div
                    className="overlayFilterButton"
                    onClick={() => closeModal("CategoryPostButton")}
                  ></div>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          {news.length !== 0 && (
                            <label className="adminTableCheckAll">
                              <input
                                onChange={() => checkSelectedNews("All news")}
                                checked={allNews}
                                type="checkbox"
                              />
                              <span className="adminTableCheckmarkAll"></span>
                            </label>
                          )}
                        </th>
                        <th style={{ paddingLeft: 0 }}>Date Posted</th>
                        <th>Article Name</th>
                        <th>
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <span>Category</span>
                            <span>
                              <FontAwesomeIcon
                                onClick={() => openModal("CategoryPostButton")}
                                style={{
                                  cursor: "pointer",
                                  float: "right",
                                  height: "20px",
                                  marginLeft: "1em",
                                }}
                                icon={faPlus}
                              />
                              <br />
                            </span>
                          </div>
                          <div className="CategoryPostButton">
                            <div className="CategoryPostButtonContainer">
                              <div className="CategoryPostList">
                                {categories.map((category) => {
                                  return category.name !== "Uncategorized" ? (
                                    <div
                                      className="CategoryPostEachList"
                                      key={category.id}
                                    >
                                      <div>
                                        <p>
                                          {category.name} <span></span>
                                        </p>
                                      </div>
                                      <div style={{ cursor: "pointer" }}>
                                        <img
                                          onClick={() =>
                                            deleteCategory(category.id)
                                          }
                                          src={WhiteTrash}
                                          alt="White Trash"
                                        />
                                      </div>
                                    </div>
                                  ) : null;
                                })}
                                <div className="CategoryPostEachList submitCategory">
                                  <form onSubmit={handleSubmit(submitCategory)}>
                                    <input
                                      name="name"
                                      type="text"
                                      placeholder="Add new category"
                                      {...register("name", {
                                        required: "This field is required",
                                      })}
                                    />
                                    <button>
                                      <img src={WhitePlus} alt="White Post" />
                                    </button>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </th>
                        <th>Posted by</th>
                        <th>Status</th>
                        {checkedNews.length === 0 ? (
                          <th
                            style={{
                              textAlign: "center",
                              paddingLeft: 0,
                              transition: ".3s ease",
                            }}
                          >
                            Action
                          </th>
                        ) : (
                          <th
                            onClick={deleteSelectedNews}
                            className="adminDeleteAll"
                            style={{ paddingLeft: "0em" }}
                          >
                            Delete <img src={TrashBin} alt="Trash bin" />
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {!isLoading && news.length > 0 ? (
                        news.map((element) => (
                          <tr
                            key={element.id}
                            className="adminTableRow"
                            style={{
                              background:
                                checkedNews.indexOf(element.id) > -1
                                  ? "rgba(202, 220, 226, 0.46)"
                                  : "#FAFAFA",
                              fontWeight:
                                checkedNews.indexOf(element.id) > -1
                                  ? "700"
                                  : "normal",
                            }}
                          >
                            <td>
                              <label className="adminTableCheck">
                                <input
                                  onChange={() => checkSelectedNews(element.id)}
                                  checked={checkedNews.indexOf(element.id) > -1}
                                  type="checkbox"
                                />
                                <span className="adminTableCheckmark"></span>
                              </label>
                            </td>
                            <td
                              onClick={() =>
                                navigate(`/admin/post/edit/${element.id}`)
                              }
                              style={{ cursor: "pointer", paddingLeft: 0 }}
                            >
                              {element.date}
                            </td>
                            <td
                              onClick={() =>
                                navigate(`/admin/post/edit/${element.id}`)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {capitalizeFirstLetter(element.title)}
                            </td>
                            <td
                              onClick={() =>
                                navigate(`/admin/post/edit/${element.id}`)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {element.Category.name}
                            </td>
                            <td
                              onClick={() =>
                                navigate(`/admin/post/edit/${element.id}`)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {element.User.username}
                            </td>
                            <td
                              onClick={() =>
                                navigate(`/admin/post/edit/${element.id}`)
                              }
                              style={{
                                color: changeStatusColor(element.status),
                                cursor: "pointer",
                              }}
                            >
                              {element.status}
                            </td>
                            {checkedNews.length === 0 ? (
                              <td
                                style={{ textAlign: "center", paddingLeft: 0 }}
                              >
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    navigate(`/admin/post/edit/${element.id}`)
                                  }
                                >
                                  <FontAwesomeIcon icon={faPenToSquare} />
                                </span>{" "}
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => deleteNewsById(element.id)}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </span>
                              </td>
                            ) : (
                              <td></td>
                            )}
                          </tr>
                        ))
                      ) : isLoading ? (
                        <div
                          style={{
                            width: "75%",
                            position: "absolute",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                            marginTop: "5em",
                          }}
                        >
                          <Lottie
                            options={lottieOptions}
                            height={200}
                            width={400}
                          />
                          <h4
                            style={{
                              color: "#0D4C86",
                            }}
                          >
                            LOADING
                          </h4>
                          <p
                            style={{
                              color: "#0D4C86",
                            }}
                          >
                            Processing your request, please wait...
                          </p>
                        </div>
                      ) : (checkedCategories.length > 0 ||
                          checkedStatus.length > 0 ||
                          checkedDate.length > 0) &&
                        news.length === 0 ? (
                        <div
                          style={{
                            width: "75%",
                            position: "absolute",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                            marginTop: "5em",
                          }}
                        >
                          <div>
                            <img
                              src={ListEmpty}
                              alt="Data not found icon"
                              width={600}
                            />
                          </div>
                          <h4
                            style={{
                              color: "#0D4C86",
                            }}
                          >
                            We can't find what you are looking for...
                          </h4>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "75%",
                            position: "absolute",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                            marginTop: "5em",
                          }}
                        >
                          <div>
                            <img
                              src={NotepadPencil}
                              alt="Data not found icon"
                              width={350}
                            />
                          </div>
                          <h4
                            style={{
                              color: "#0D4C86",
                            }}
                          >
                            You have not written any post yet
                          </h4>
                          <p
                            style={{
                              color: "#0D4C86",
                            }}
                          >
                            Add new post to start..
                          </p>
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : null}

          {/* Add & Edit Post */}
          {params === "post" &&
          (crud === "add" || (crud === "edit" && editId)) ? (
            <PostForm
              navigate={navigate}
              categories={categories}
              isLoading={isLoading}
              setLoading={setLoading}
              changeStatus={changeStatus}
              handleStatusName={handleStatusName}
              handleStatusImage={handleStatusImage}
              statusName={statusName}
              EllipseDraft={EllipseDraft}
              EllipseHidden={EllipseHidden}
              EllipsePublished={EllipsePublished}
              lottieOptions={lottieOptions}
              handleSave={handleSave}
              setShowAlertWorkshop={setShowAlertWorkshop}
              setWorkshopAlertMsg={setWorkshopAlertMsg}
              editId={editId}
              editForm={editForm}
              thumbnailImageFailedToUploadMsg={thumbnailImageFailedToUploadMsg}
              // contentImageFailedToUploadMsg={contentImageFailedToUploadMsg}
            />
          ) : null}

          {/* ISA Academy workshop */}
          {params === "isaAcademy-workshops" ||
          params === "isaAcademy-testimonial" ? (
            <IsaAcademyForm
              isLoading={isLoading}
              setLoading={setLoading}
              setShowAlertWorkshop={setShowAlertWorkshop}
              setWorkshopAlertMsg={setWorkshopAlertMsg}
              fetchAllWorkshops={fetchAllWorkshops}
              fetchAllTestimonials={fetchAllTestimonials}
              workshops={workshops}
              testimonials={testimonials}
            />
          ) : null}

          {/* Activities */}
          {params === "activities" ? (
            <Activities
              isLoading={isLoading}
              setLoading={setLoading}
              setShowAlertWorkshop={setShowAlertWorkshop}
              setWorkshopAlertMsg={setWorkshopAlertMsg}
              activities={activities}
              fetchAllActivities={fetchAllActivities}
            />
          ) : null}

          {/* Founder's Story */}
          {params === "founders-story" ? (
            <FoundersStory
              isLoading={isLoading}
              setLoading={setLoading}
              setShowAlertWorkshop={setShowAlertWorkshop}
              setWorkshopAlertMsg={setWorkshopAlertMsg}
              foundersStories={foundersStories}
              fetchAllFoundersStories={fetchAllFoundersStories}
            />
          ) : null}

          {/* Books */}
          {params === "books" ? (
            <Book
              isLoading={isLoading}
              setLoading={setLoading}
              setShowAlertWorkshop={setShowAlertWorkshop}
              setWorkshopAlertMsg={setWorkshopAlertMsg}
              books={books}
              fetchAllBooks={fetchAllBooks}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
