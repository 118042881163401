import React, { useEffect, useState } from "react";

// Components
import InputFacilitator from "./inputFacilitator";
import InputSchedule from "./inputSchedules";
import WorkshopDropdownSelect from "./WorkshopDropdownSelect";
import Loader from "../../../components/Loader";
import EditorText from "../../../components/EditorText";

// CSS Styling
import "../../../styles/admin.css";
import "../../../styles/isaAcademy.css";

// Icons
import uploadImageBtn from "../../../images/icons/admin/uploadImageBtn.png";
import addMoreIcon from "../../../images/icons/admin/addMoreIcon.png";
import iconChangeImage from "../../../images/icons/admin/changeImageIcon.png";

// Axios and API
import { addTag } from "../../../api/addTag";
import { fetchTags } from "../../../api/fetchTags";
import { addAcademyWorkshop } from "../../../api/addAcademyWorkshop";
import { fetchWorkshopDetail } from "../../../api/fetchWorkshopDetail";
import { editWorkshop } from "../../../api/editWorkshop";

// Sweet Alert v2
import Swal from "sweetalert2";

// Moment.js
import moment from "moment";

// React lottie
import Lottie from "react-lotties";
import PaperPlane from "../../../lotties/paperplane.json";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const WorkshopForm = (props) => {
  const {
    navigate,
    editId,
    setShowAlertWorkshop,
    setWorkshopAlertMsg,
    fetchAllWorkshops,
  } = props;
  const forceUpdate = useForceUpdate();

  const [tagOptions, setTagOptions] = useState([]);

  const [facilitatorCounter, setFacilitatorCounter] = useState([0]);
  const [scheduleCounter, setscheduleCounter] = useState([0]);

  const [typeOfWork, setTypeOfWork] = useState(null);
  const [displayOnHomePage, setDisplayOnHomePage] = useState(null);
  const [tags, setTags] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [facilitators, setFacilitators] = useState([]);
  const [coverImageToView, setCoverImageToView] = useState(null);
  const [coverImageToSave, setCoverImageToSave] = useState(null);
  const [mainImageToView, setMainImageToView] = useState(null);
  const [mainImageToSave, setMainImageToSave] = useState(null);
  const [locationVal, setLocationVal] = useState("");
  const [inputSummary, setInputSummary] = useState("");
  const [whatYouWillLearn, setWhatYouWillLearn] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [howToRegister, setHowToRegister] = useState("");
  const [registrationLink, setRegistrationLink] = useState("");
  const [workshopTitle, setWorkshopTitle] = useState("");

  const [typeOfWorkEmpty, setTypeOfWorkEmpty] = useState(false);
  const [displayOnHomePageEmpty, setDisplayOnHomePageEmpty] = useState(false);
  const [locationValEmpty, setLocationValEmpty] = useState(false);
  const [inputSummaryEmpty, setInputSummaryEmpty] = useState(false);
  const [whatYouWillLearnEmpty, setWhatYouWillLearnEmpty] = useState(false);
  const [targetAudienceEmpty, setTargetAudienceEmpty] = useState(false);
  const [howToRegisterEmpty, setHowToRegisterEmpty] = useState(false);
  const [registrationLinkEmpty, setRegistrationLinkEmpty] = useState(false);
  const [coverImageEmpty, setCoverImageEmpty] = useState(false);
  const [mainImageEmpty, setMainImageEmpty] = useState(false);
  const [scheduleEmpty, setScheduleEmpty] = useState(false);
  const [facilitatorEmpty, setFacilitatorEmpty] = useState(false);
  const [tagEmpty, setTagEmpty] = useState(false);
  const [workshopTitleEmpty, setWorkshopTitleEmpty] = useState(false);
  const [coverImageFileTooLargeMsg, setCoverImageFileTooLargeMsg] =
    useState("");
  const [mainImageFileTooLargeMsg, setMainImageFileTooLargeMsg] = useState("");
  const [coverImageFailedToUploadMsg, setCoverImageFailedToUploadMsg] =
    useState("");
  const [mainImageFailedToUploadMsg, setMainImageFailedToUploadMsg] =
    useState("");
  const [coverImageSizeTooBigMsg, setCoverImageSizeTooBigMsg] = useState("");
  const [mainImageSizeTooBigMsg, setMainImageSizeTooBigMsg] = useState("");

  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: PaperPlane,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    fetchAllTags(); // eslint-disable-next-line
    if (editId) {
      fetchOneWorkshop(editId);
    }
  }, [editId]);

  const fetchOneWorkshop = async (workshopId) => {
    setIsLoadingSave(true);
    const response = await fetchWorkshopDetail(workshopId);
    if (response && response.status === 200 && response.data) {
      const {
        type_of_workshop,
        location = "",
        workshop_title = "",
        cover_image_url,
        input_summary = "",
        main_image_url,
        what_you_will_learn = "",
        target_audience = "",
        how_to_register = "",
        register_link = "",
        Facilitators = [],
        Schedules = [],
        AcademyWorkshopTags = [],
        display_on_home_page,
      } = response.data || {};
      setTypeOfWork({ value: type_of_workshop, label: type_of_workshop });
      setDisplayOnHomePage({
        value: display_on_home_page ? "Yes" : "No",
        label: display_on_home_page ? "Yes" : "No",
      });
      setCoverImageToView({ url: cover_image_url });
      setMainImageToView({ url: main_image_url });
      setLocationVal(location);
      setInputSummary(input_summary);
      setWhatYouWillLearn(what_you_will_learn);
      setTargetAudience(target_audience);
      setHowToRegister(how_to_register);
      setRegistrationLink(register_link);
      setWorkshopTitle(workshop_title);

      const finalFacilitators =
        Facilitators &&
        Array.isArray(Facilitators) &&
        Facilitators.length > 0 &&
        Facilitators.map((facilitator) => {
          const {
            facilitator_image_url,
            facilitator_name,
            facilitator_profile,
            id,
          } = facilitator || {};
          const objFacilitator = {
            facilitatorImg: facilitator_image_url,
            facilitatorName: facilitator_name,
            facilitatorProfile: facilitator_profile,
            facilitator_id: id,
          };
          return objFacilitator;
        });
      setFacilitators(finalFacilitators);

      const finalSchedules =
        Schedules &&
        Array.isArray(Schedules) &&
        Schedules.length > 0 &&
        Schedules.map((schedule) => {
          const {
            method_of_learning,
            investment_fee,
            start_date,
            end_date,
            id,
          } = schedule || {};
          const objSchedule = {
            methodOfLearning: {
              value: method_of_learning,
              label: method_of_learning,
            },
            investmentFee: investment_fee,
            start_date: `${moment(start_date).format("DD/MM/YYYY")}`,
            end_date: `${moment(end_date).format("DD/MM/YYYY")}`,
            schedule_id: id,
          };
          return objSchedule;
        });
      setSchedules(finalSchedules);

      const finalTags =
        AcademyWorkshopTags &&
        Array.isArray(AcademyWorkshopTags) &&
        AcademyWorkshopTags.length > 0 &&
        AcademyWorkshopTags.map((academyWorkshopTag) => {
          const { Tag: { id, tag_name } = {} } = academyWorkshopTag || {};
          const objTag = { id, label: tag_name, value: tag_name };
          return objTag;
        });
      setTags(finalTags);

      setIsLoadingSave(false);
    }
  };

  const fetchAllTags = async () => {
    setIsLoadingSave(true);
    const response = await fetchTags();
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      const finalTags = [];
      data.forEach((el) => {
        const { tag_name, id } = el || {};
        finalTags.push({ id, label: tag_name, value: tag_name });
      });
      setTagOptions(finalTags);
      setIsLoadingSave(false);
    }
    forceUpdate();
  };

  const onDropdownScheduleChange = (val, index, key) => {
    let finalSchedules = schedules;
    if (schedules.length === 0) {
      finalSchedules.push({ [`${key}`]: val });
    } else {
      if (finalSchedules[index] && finalSchedules[index][`${key}`]) {
        finalSchedules[index][`${key}`] = val;
      } else {
        finalSchedules[index] = { ...finalSchedules[index], [`${key}`]: val };
      }
    }
    setSchedules(finalSchedules);
    forceUpdate();
  };

  const onImageChange = (files, flagger, index, key) => {
    if (files && files[0]) {
      const { size } = files[0] || {};
      if (flagger === "facilitator") {
        let finalFacilitators = facilitators;
        if (facilitators.length === 0) {
          if (size >= 1045504) {
            finalFacilitators.push({
              [`${key}`]: URL.createObjectURL(files[0]),
              [`facilitatorImageToSave`]: files[0],
              fileName: files[0].name,
              [`isFileSizeTooLargeMsg`]: "File too large (Max: 1mb)",
            });
          } else {
            finalFacilitators.push({
              [`${key}`]: URL.createObjectURL(files[0]),
              [`facilitatorImageToSave`]: files[0],
              fileName: files[0].name,
              [`isFileSizeTooLargeMsg`]: "",
            });
          }
        } else {
          if (size >= 1045504) {
            finalFacilitators[index] = {
              ...finalFacilitators[index],
              [`facilitatorImageToSave`]: files[0],
              fileName: files[0].name,
              [`${key}`]: URL.createObjectURL(files[0]),
              [`isFileSizeTooLargeMsg`]: "File too large (Max: 1mb)",
            };
          } else {
            finalFacilitators[index] = {
              ...finalFacilitators[index],
              [`facilitatorImageToSave`]: files[0],
              fileName: files[0].name,
              [`${key}`]: URL.createObjectURL(files[0]),
              [`isFileSizeTooLargeMsg`]: "",
            };
          }
        }
        setFacilitators(finalFacilitators);
      } else if (flagger === "coverImage") {
        if (size >= 1045504) {
          setCoverImageSizeTooBigMsg("File too large (Max: 1mb)");
        } else {
          setCoverImageSizeTooBigMsg("");
        }
        setCoverImageToSave(files[0]);
        setCoverImageToView({
          fileName: files[0].name,
          url: URL.createObjectURL(files[0]),
        });
      } else if (flagger === "mainImage") {
        if (size >= 1045504) {
          setMainImageSizeTooBigMsg("File too large (Max: 1mb)");
        } else {
          setMainImageSizeTooBigMsg("");
        }
        setMainImageToSave(files[0]);
        setMainImageToView({
          fileName: files[0].name,
          url: URL.createObjectURL(files[0]),
        });
      }
      forceUpdate();
    }
  };

  const onInputChange = (flagger, str, setter, key) => {
    if (!flagger) {
      let index = setter;
      if (key === "facilitatorProfile" || key === "facilitatorName") {
        let finalFacilitators = facilitators;
        if (facilitators.length === 0) {
          finalFacilitators.push({ [`${key}`]: str });
        } else {
          if (finalFacilitators[index] && finalFacilitators[index][`${key}`]) {
            finalFacilitators[index][`${key}`] = str;
          } else {
            finalFacilitators[index] = {
              ...finalFacilitators[index],
              [`${key}`]: str,
            };
          }
        }
        setFacilitators(finalFacilitators);
      } else {
        let finalSchedules = schedules;
        if (schedules.length === 0) {
          finalSchedules.push({ [`${key}`]: str });
        } else {
          if (finalSchedules[index] && finalSchedules[index][`${key}`]) {
            finalSchedules[index][`${key}`] = str;
          } else {
            finalSchedules[index] = {
              ...finalSchedules[index],
              [`${key}`]: str,
            };
          }
        }
        setSchedules(finalSchedules);
      }
      forceUpdate();
    } else {
      setter(str);
    }
  };

  const addMoreScheduleInput = () => {
    const lastIndexScheduleCounter =
      scheduleCounter[scheduleCounter.length - 1];
    if (schedules.length === 0) {
      // munculin alert
      setShowAlertWorkshop(true);
      setWorkshopAlertMsg(
        "Alert! Please fill in the first Schedule to be able to add more field"
      );
    } else {
      if (!schedules[lastIndexScheduleCounter]) {
        // munculin alert
        setShowAlertWorkshop(true);
        setWorkshopAlertMsg(
          "Alert! Please fill in the first Schedule to be able to add more field"
        );
      } else {
        const lastIndexSchedule = schedules[lastIndexScheduleCounter];
        if (
          !lastIndexSchedule["methodOfLearning"] ||
          !lastIndexSchedule["selected_start_date"] ||
          !lastIndexSchedule["selected_end_date"] ||
          !lastIndexSchedule["investmentFee"]
        ) {
          // munculin alert
          setShowAlertWorkshop(true);
          setWorkshopAlertMsg(
            "Alert! Please fill in the first Schedule to be able to add more field"
          );
        } else {
          setShowAlertWorkshop(false);
          setWorkshopAlertMsg("");
          const tempScheduleCounter = scheduleCounter;
          const currentCounter = scheduleCounter.length;
          if (currentCounter < 6) {
            tempScheduleCounter.push(currentCounter);
            setscheduleCounter(tempScheduleCounter);
            forceUpdate();
          }
        }
      }
    }
  };

  const addMoreFacilitatorInput = () => {
    const lastIndexFacilitatorCounter =
      facilitatorCounter[facilitatorCounter.length - 1];
    if (facilitators.length === 0) {
      // munculin alert
      setShowAlertWorkshop(true);
      setWorkshopAlertMsg(
        "Alert! Please fill in the first Facilitator to be able to add more field"
      );
    } else {
      if (!facilitators[lastIndexFacilitatorCounter]) {
        // munculin alert
        setShowAlertWorkshop(true);
        setWorkshopAlertMsg(
          "Alert! Please fill in the first Facilitator to be able to add more field"
        );
      } else {
        const lastIndexFacilitator = facilitators[lastIndexFacilitatorCounter];
        if (
          !lastIndexFacilitator["facilitatorImg"] ||
          !lastIndexFacilitator["facilitatorName"] ||
          !lastIndexFacilitator["facilitatorProfile"]
        ) {
          // munculin alert
          setShowAlertWorkshop(true);
          setWorkshopAlertMsg(
            "Alert! Please fill in the first Facilitator to be able to add more field"
          );
        } else {
          setShowAlertWorkshop(false);
          setWorkshopAlertMsg("");
          const tempFacilitatorCounter = facilitatorCounter;
          const currentCounter = facilitatorCounter.length;
          if (currentCounter < 4) {
            tempFacilitatorCounter.push(currentCounter);
            setFacilitatorCounter(tempFacilitatorCounter);
            forceUpdate();
          }
        }
      }
    }
  };

  const onDropdownChange = (val, key, flagger) => {
    if (flagger) {
      const isSameProperty = (val, tagOptions) =>
        val.value === tagOptions.value;

      const onlyInLeft = (left, right, compareFunction) =>
        left.filter(
          (leftValue) =>
            !right.some((rightValue) => compareFunction(leftValue, rightValue))
        );

      const onlyInVal = onlyInLeft(val, tagOptions, isSameProperty);
      const onlyInTagOptions = onlyInLeft(tagOptions, val, isSameProperty);

      const result = [...onlyInVal, ...onlyInTagOptions];

      let arrTagOptions = tagOptions;
      result.forEach(async (el) => {
        if (el["__isNew__"]) {
          const { label, value } = el || {};
          const createdTag = await addTag({ tag_name: value });
          if (createdTag && createdTag.status === 201 && createdTag.data) {
            arrTagOptions.push({ label, value, id: createdTag.data.id });
            setTagOptions(arrTagOptions);
            setTags([...tags, { label, value, id: createdTag.data.id }]);
          }
        }
      });
    }
    key(val);
  };

  const onChangeInputDateSchedule = (date, index, flagger, keyDate) => {
    let finalSchedules = schedules;
    if (flagger === false) {
      finalSchedules[index] = {
        ...finalSchedules[index],
        [`selected_date`]: date,
        [`date`]: `${moment(date).format("DD/MM/YYYY")}`,
      };
    } else {
      if (keyDate === "startDate") {
        finalSchedules[index] = {
          ...finalSchedules[index],
          [`selected_start_date`]: date,
          [`start_date`]: `${moment(date).format("DD/MM/YYYY")}`,
        };
      } else {
        finalSchedules[index] = {
          ...finalSchedules[index],
          [`selected_end_date`]: date,
          [`end_date`]: `${moment(date).format("DD/MM/YYYY")}`,
        };
      }
    }
    setSchedules(finalSchedules);
    forceUpdate();
  };

  const checkAllInputs = (e) => {
    e.preventDefault();
    setIsLoadingSave(true);

    let isTypeOfWorkEmpty = false;
    let isDisplayOnHomePage = false;
    let isLocationValEmpty = false;
    let isInputSummaryEmpty = false;
    let isWhatYouWillLearnEmpty = false;
    let isTargetAudienceEmpty = false;
    let isHowToRegisterEmpty = false;
    let isRegistrationLinkEmpty = false;
    let isCoverImageEmpty = false;
    let isMainImageEmpty = false;
    let isScheduleEmpty = false;
    let isFacilitatorEmpty = false;
    let isTagEmpty = false;
    let isWorkshopTitleEmpty = false;
    let errorFacilitatorImageFileSizeTooLarge = false;

    if (!typeOfWork) {
      isTypeOfWorkEmpty = true;
    }
    if (!displayOnHomePage) {
      isDisplayOnHomePage = true;
    }
    if (!locationVal) {
      isLocationValEmpty = true;
    }
    if (!inputSummary) {
      isInputSummaryEmpty = true;
    }
    if (!whatYouWillLearn) {
      isWhatYouWillLearnEmpty = true;
    }
    if (!targetAudience) {
      isTargetAudienceEmpty = true;
    }
    if (!howToRegister) {
      isHowToRegisterEmpty = true;
    }
    if (!registrationLink) {
      isRegistrationLinkEmpty = true;
    }
    if (!workshopTitle) {
      isWorkshopTitleEmpty = true;
    }

    if (!editId) {
      if (!coverImageToSave || !coverImageToView) {
        isCoverImageEmpty = true;
      }
      if (!mainImageToSave || !mainImageToView) {
        isMainImageEmpty = true;
      }
    }

    if (schedules.length <= 0) {
      isScheduleEmpty = true;
    } else {
      let finalSchedules = schedules;
      schedules.forEach((schedule, index) => {
        const {
          methodOfLearning = "",
          selected_end_date = "",
          selected_start_date = "",
          investmentFee = "",
        } = schedule || {};
        if (methodOfLearning) {
          finalSchedules[index]["emptyMethodOfLearning"] = false;
          if (investmentFee) {
            finalSchedules[index]["emptyInvestmentFee"] = false;
          } else {
            finalSchedules[index]["emptyInvestmentFee"] = true;
            isScheduleEmpty = true;
          }
          if (!editId) {
            if (selected_start_date) {
              finalSchedules[index]["emptyStartDate"] = false;
            } else {
              finalSchedules[index]["emptyStartDate"] = true;
              isScheduleEmpty = true;
            }
            if (selected_end_date) {
              finalSchedules[index]["emptyEndDate"] = false;
            } else {
              finalSchedules[index]["emptyEndDate"] = true;
              isScheduleEmpty = true;
            }
          }
        } else {
          finalSchedules[index]["emptyMethodOfLearning"] = true;
          isScheduleEmpty = true;
        }
      });
      setSchedules(finalSchedules);
      forceUpdate();
    }

    if (facilitators.length <= 0) {
      isFacilitatorEmpty = true;
    } else {
      let finalFacilitators = facilitators;
      facilitators.forEach((facilitator, index) => {
        const {
          facilitatorImg = "",
          facilitatorProfile = "",
          facilitatorName = "",
          isFileSizeTooLargeMsg,
        } = facilitator || {};
        if (facilitatorImg) {
          finalFacilitators[index]["emptyFacilitatorImg"] = false;
        } else {
          finalFacilitators[index]["emptyFacilitatorImg"] = true;
          isFacilitatorEmpty = true;
        }
        if (facilitatorName) {
          finalFacilitators[index]["emptyFacilitatorName"] = false;
        } else {
          finalFacilitators[index]["emptyFacilitatorName"] = true;
          isFacilitatorEmpty = true;
        }
        if (!facilitatorProfile) {
          finalFacilitators[index]["emptyFacilitatorProfile"] = true;
          isFacilitatorEmpty = true;
        }
        if (isFileSizeTooLargeMsg) {
          errorFacilitatorImageFileSizeTooLarge = true;
        }
        // if (!facilitatorProfile) {
        //   if (facilitatorProfile.length > 255) {
        //     isFacilitatorEmpty = true;
        //   } else {
        //     isFacilitatorEmpty = false;
        //   }
        //   finalFacilitators[index]["emptyFacilitatorProfile"] = false;
        // } else {
        //   finalFacilitators[index]["emptyFacilitatorProfile"] = true;
        //   isFacilitatorEmpty = true;
        // }
      });
      setFacilitators(finalFacilitators);
      forceUpdate();
    }

    if (tags.length <= 0) {
      isTagEmpty = true;
    }

    setTypeOfWorkEmpty(isTypeOfWorkEmpty);
    setDisplayOnHomePageEmpty(isDisplayOnHomePage);
    setLocationValEmpty(isLocationValEmpty);
    setInputSummaryEmpty(isInputSummaryEmpty);
    setWhatYouWillLearnEmpty(isWhatYouWillLearnEmpty);
    setTargetAudienceEmpty(isTargetAudienceEmpty);
    setHowToRegisterEmpty(isHowToRegisterEmpty);
    setRegistrationLinkEmpty(isRegistrationLinkEmpty);
    setCoverImageEmpty(isCoverImageEmpty);
    setMainImageEmpty(isMainImageEmpty);
    setScheduleEmpty(isScheduleEmpty);
    setFacilitatorEmpty(isFacilitatorEmpty);
    setTagEmpty(isTagEmpty);
    setWorkshopTitleEmpty(isWorkshopTitleEmpty);

    if (
      isTypeOfWorkEmpty !== true &&
      isDisplayOnHomePage !== true &&
      isLocationValEmpty !== true &&
      isInputSummaryEmpty !== true &&
      isWhatYouWillLearnEmpty !== true &&
      isTargetAudienceEmpty !== true &&
      isHowToRegisterEmpty !== true &&
      isRegistrationLinkEmpty !== true &&
      isScheduleEmpty !== true &&
      isFacilitatorEmpty !== true &&
      isTagEmpty !== true &&
      isWorkshopTitleEmpty !== true &&
      errorFacilitatorImageFileSizeTooLarge !== true
    ) {
      if (editId) {
        handleSave();
      } else {
        if (isCoverImageEmpty !== true && isMainImageEmpty !== true) {
          handleSave();
        } else {
          setIsLoadingSave(false);
          setShowAlertWorkshop(true);
          setWorkshopAlertMsg("Please check the error fields");
        }
      }
    } else {
      setIsLoadingSave(false);
      setShowAlertWorkshop(true);
      setWorkshopAlertMsg("Please check the error fields");
    }
  };

  const handleSave = async () => {
    const tagIds = [];
    tags.forEach((tag) => {
      tagIds.push(tag.id);
    });

    const finalSchedules = [];
    const finalFacilitators = [];

    schedules.forEach((schedule) => {
      const {
        methodOfLearning,
        investmentFee,
        selected_start_date,
        selected_end_date,
        schedule_id,
      } = schedule;
      let start;
      let end;
      let duration;
      if (selected_start_date) {
        start = moment(selected_start_date, "YYYY-MM-DD");
      }
      if (selected_end_date) {
        end = moment(selected_end_date, "YYYY-MM-DD");
      }
      if (start && end) {
        duration = Math.floor(moment.duration(end.diff(start)).asDays()) + 1; // +1 because same date equals to 1 day gap
        finalSchedules.push({
          [`method_of_learning`]: methodOfLearning.value,
          [`investment_fee`]: investmentFee,
          [`duration`]:
            duration < 2 ? "1 day workshop" : `${duration} days workshop`,
          [`start_date`]: new Date(selected_start_date).toISOString(),
          [`end_date`]: new Date(selected_end_date).toISOString(),
          [`schedule_id`]: schedule_id ? schedule_id : "",
        });
      } else {
        finalSchedules.push({
          [`method_of_learning`]: methodOfLearning.value,
          [`investment_fee`]: investmentFee,
          [`schedule_id`]: schedule_id ? schedule_id : "",
        });
      }
    });

    const data = new FormData();
    data.append("type_of_workshop", typeOfWork.value);
    data.append("display_on_home_page", displayOnHomePage.value);
    data.append("location", locationVal);
    data.append("input_summary", inputSummary);
    data.append("what_you_will_learn", whatYouWillLearn);
    data.append("target_audience", targetAudience);
    data.append("how_to_register", howToRegister);
    data.append("register_link", registrationLink);
    data.append("workshop_title", workshopTitle);
    data.append("tagIds", JSON.stringify(tagIds));
    data.append("schedules", JSON.stringify(finalSchedules));
    if (!editId) {
      data.append("coverImage", coverImageToSave);
      data.append("mainImage", mainImageToSave);
    } else {
      if (coverImageToSave) {
        data.append("coverImage", coverImageToSave);
      }
      if (mainImageToSave) {
        data.append("mainImage", mainImageToSave);
      }
    }

    facilitators.forEach((facilitator) => {
      const {
        facilitatorImageToSave = "",
        facilitatorProfile,
        facilitatorName,
        facilitator_id,
      } = facilitator;
      if (facilitatorImageToSave) {
        data.append(`facilitator_images`, facilitatorImageToSave);
      }
      finalFacilitators.push({
        [`facilitator_name`]: facilitatorName,
        [`facilitator_profile`]: facilitatorProfile,
        [`facilitator_id`]: facilitator_id,
        [`is_facilitator_image_edited`]: facilitatorImageToSave ? true : false,
      });
    });
    data.append("facilitators", JSON.stringify(finalFacilitators));

    try {
      let result;
      if (editId) {
        result = await editWorkshop(editId, data);
      } else {
        result = await addAcademyWorkshop(data);
      }
      if (result) {
        Swal.fire({
          customClass: {
            confirmButton: "publishButton",
            title: "publishTitle",
            popup: "publishPopup",
          },
          showCloseButton: true,
          confirmButtonColor: "#1F81DC",
          title: "Success!",
          text: editId
            ? "This workshops has been succesfully edited"
            : "This workshops has been succesfully created",
          confirmButtonText: "Okay, got it!",
        });
        fetchAllWorkshops();
        navigate(`/admin/isaAcademy-workshops`);
      }
    } catch (err) {
      const {
        coverImage = "",
        mainImage = "",
        facilitatorImg = "",
        index = "",
        errorName,
        errorMsg,
        message,
      } = err.response.data || {};
      let finalFacilitators = facilitators;

      if (
        message &&
        (message === "Unauthorized" ||
          message === "Unauthenticated" ||
          message === "TokenExpiredError")
      ) {
        Swal.fire({
          position: "top-end",
          text: "Token expired, please re-login",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          width: "450px",
        });
        setIsLoadingSave(false);
        localStorage.removeItem("isVerified");
        localStorage.removeItem("access_token");
        localStorage.removeItem("email");
        navigate("/signIn");
      } else if (errorName && errorName === "Bad request") {
        if (coverImage) {
          setCoverImageFailedToUploadMsg(errorMsg);
        } else {
          setCoverImageFailedToUploadMsg("");
        }

        if (mainImage) {
          setMainImageFailedToUploadMsg(errorMsg);
        } else {
          setMainImageFailedToUploadMsg("");
        }

        if (facilitatorImg) {
          finalFacilitators[index][`failedToUpload`] = errorMsg;
          setFacilitators(finalFacilitators);
          forceUpdate();
        } else {
          facilitators.forEach((el, idx) => {
            finalFacilitators[idx] = {
              ...el,
              [`failedToUpload`]: "",
            };
          });
          setFacilitators(finalFacilitators);
          forceUpdate();
        }
      } else if (errorName && errorName === "File too large (Max: 1mb)") {
        if (coverImage) {
          setCoverImageFileTooLargeMsg(errorName);
        } else {
          setCoverImageFileTooLargeMsg("");
        }

        if (mainImage) {
          setMainImageFileTooLargeMsg(errorName);
        } else {
          setMainImageFileTooLargeMsg("");
        }

        if (facilitatorImg) {
          finalFacilitators[index][`imageFileTooLarge`] = errorName;
          setFacilitators(finalFacilitators);
          forceUpdate();
        } else {
          facilitators.forEach((el, idx) => {
            finalFacilitators[idx] = {
              ...el,
              [`imageFileTooLarge`]: "",
            };
          });
          setFacilitators(finalFacilitators);
          forceUpdate();
        }
      } else {
        Swal.fire({
          position: "top-end",
          text: "Please fill all required elements",
          showConfirmButton: false,
          timer: 1500,
          backdrop: false,
          width: "450px",
        });
      }
    } finally {
      setIsLoadingSave(false);
    }
  };

  const handleClickMethodOfLearning = (index, value) => {
    let finalSchedules = schedules;
    if (finalSchedules[index]) {
      if (finalSchedules[index].hasOwnProperty("addMarginBottom")) {
        finalSchedules[index] = {
          ...finalSchedules[index],
          [`addMarginBottom`]: value,
        };
      } else {
        finalSchedules[index] = {
          ...finalSchedules[index],
          [`addMarginBottom`]: value,
        };
      }
    }
    setSchedules(finalSchedules);
    forceUpdate();
  };

  return (
    <div className="adminPostList">
      {isLoadingSave ? null : (
        <div className="adminPostListTitleContainer">
          <div className="adminPostListTitle">
            <p>{editId ? "Edit Workshop" : "Add New"}</p>
          </div>
        </div>
      )}
      {isLoadingSave ? (
        <div
          style={{
            width: "75%",
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            marginTop: "5em",
          }}
        >
          <Lottie options={lottieOptions} height={200} width={400} />
          <h4 style={{ color: "#0D4C86" }}>LOADING</h4>
          <p style={{ color: "#0D4C86" }}>
            Processing your request, please wait...
          </p>
        </div>
      ) : (
        <div className="adminPostAdd">
          <form>
            <div className="typeOfWorkAndTagInput">
              <div className="eachContainer">
                <div className="eachLabel">Type of workshop</div>
                <WorkshopDropdownSelect
                  placeholder="Select type of workshop"
                  options={[
                    { value: "Free", label: "Free" },
                    { value: "Paid", label: "Paid" },
                  ]}
                  selectStyle={{
                    height: "50px",
                    borderRadius: "40px",
                    fontSize: "15px",
                    padding: "5px",
                    borderColor: "#0D4C86",
                  }}
                  optionStyle={{
                    backgroundColor: "#0D4C86",
                    fontSize: "15px",
                    color: "#FFFFFF",
                  }}
                  onChange={(e) => onDropdownChange(e, setTypeOfWork)}
                  value={typeOfWork}
                />
                {typeOfWorkEmpty ? (
                  <div className="errorMessage">
                    <p>This field is required</p>
                  </div>
                ) : null}
              </div>
              <div className="eachContainer">
                <div className="eachLabel">Location</div>
                <input
                  required
                  name="location"
                  type="text"
                  className="inputBox"
                  placeholder="Type in location"
                  value={locationVal}
                  onChange={({ target: { value } }) =>
                    onInputChange(true, value, setLocationVal)
                  }
                ></input>
                {locationValEmpty ? (
                  <div className="errorMessage">
                    <p>This field is required</p>
                  </div>
                ) : null}
              </div>
              <div className="eachContainer">
                <div className="eachLabel">Display on website home page</div>
                <WorkshopDropdownSelect
                  placeholder="Display on website home page ?"
                  options={[
                    { value: "Yes", label: "Yes" },
                    { value: "No", label: "No" },
                  ]}
                  selectStyle={{
                    height: "50px",
                    borderRadius: "40px",
                    fontSize: "15px",
                    padding: "5px",
                    borderColor: "#0D4C86",
                  }}
                  optionStyle={{
                    backgroundColor: "#0D4C86",
                    fontSize: "15px",
                    color: "#FFFFFF",
                  }}
                  onChange={(e) => onDropdownChange(e, setDisplayOnHomePage)}
                  value={displayOnHomePage}
                />
                {displayOnHomePageEmpty ? (
                  <div className="errorMessage">
                    <p>This field is required</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Workshop Title</div>
              </div>
              <textarea
                required
                name="workshopTitle"
                type="text"
                className="xSmallInputBox"
                placeholder="Please fill in workshop title"
                value={workshopTitle}
                onChange={({ target: { value } }) =>
                  onInputChange(true, value, setWorkshopTitle)
                }
              ></textarea>
              {workshopTitleEmpty ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="eachLabel">Upload cover image</div>
              <div
                className={
                  !coverImageToView
                    ? "inputImageContainer"
                    : "inputImageContainerNoPointer"
                }
                onClick={() =>
                  !coverImageToView
                    ? document.querySelector(`.input-field-cover-image`).click()
                    : null
                }
              >
                <input
                  type="file"
                  accept="image/*"
                  className={`input-field-cover-image`}
                  hidden
                  onChange={({ target: { files } }) =>
                    onImageChange(files, "coverImage")
                  }
                />
                <div className="imageToViewContainer">
                  <img
                    src={
                      coverImageToView && coverImageToView.url
                        ? coverImageToView.url
                        : uploadImageBtn
                    }
                    style={
                      coverImageToView && coverImageToView.url
                        ? { maxWidth: 250, maxHeight: 175 }
                        : { maxWidth: 35, maxHeight: 35 }
                    }
                    alt="coverImage"
                  ></img>
                  {!coverImageFailedToUploadMsg &&
                  coverImageToView &&
                  coverImageToView.fileName ? (
                    <div className="imageFileNameFontStyle">
                      {coverImageToView.fileName}
                    </div>
                  ) : null}
                  {coverImageFailedToUploadMsg && (
                    <div className="errorFailedToUpload">
                      <p>{coverImageFailedToUploadMsg}</p>
                    </div>
                  )}
                </div>
                {coverImageToView && (
                  <div
                    className="btnChangeImage"
                    onClick={() =>
                      document.querySelector(`.input-field-cover-image`).click()
                    }
                  >
                    <img
                      src={iconChangeImage}
                      alt="iconChangeImage"
                      width={17}
                      height={17}
                    ></img>
                    <div className="changeImageLabel">Change image</div>
                  </div>
                )}
                {coverImageToView ? null : (
                  <div className="labelImage">
                    {"Drag & drop your file(s) here"}
                  </div>
                )}
                {coverImageToView ? null : (
                  <div className="placeholderImage">
                    {"Recommended size is 300 x 300 px"}
                  </div>
                )}
              </div>
              {coverImageEmpty ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : coverImageSizeTooBigMsg || coverImageFileTooLargeMsg ? (
                <div className="errorMessage">
                  <p>
                    {coverImageSizeTooBigMsg
                      ? coverImageSizeTooBigMsg
                      : coverImageFileTooLargeMsg}
                  </p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Input Summary</div>
                <div className="secondLabel">
                  This description summary will appear on workshop list page
                </div>
              </div>
              <textarea
                required
                name="inputSummary"
                type="text"
                className="bigInputBox"
                placeholder="Please input the summary here..."
                value={inputSummary}
                onChange={({ target: { value } }) =>
                  onInputChange(true, value, setInputSummary)
                }
              ></textarea>
              {inputSummaryEmpty && !inputSummary ? (
                <div className="errorMessage">
                  <p>This field is required.</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="eachLabel">Upload main image</div>
              <div
                className={
                  !mainImageToView
                    ? "inputImageContainer"
                    : "inputImageContainerNoPointer"
                }
                onClick={() =>
                  !mainImageToView
                    ? document.querySelector(`.input-field-main-image`).click()
                    : null
                }
              >
                <input
                  type="file"
                  accept="image/*"
                  className={`input-field-main-image`}
                  hidden
                  onChange={({ target: { files } }) =>
                    onImageChange(files, "mainImage")
                  }
                />
                <div className="imageToViewContainer">
                  <img
                    src={
                      mainImageToView && mainImageToView.url
                        ? mainImageToView.url
                        : uploadImageBtn
                    }
                    style={
                      mainImageToView && mainImageToView.url
                        ? { maxWidth: 250, maxHeight: 175 }
                        : { maxWidth: 35, maxHeight: 35 }
                    }
                    alt="mainImage"
                  ></img>
                  {!mainImageFailedToUploadMsg &&
                  mainImageToView &&
                  mainImageToView.fileName ? (
                    <div className="imageFileNameFontStyle">
                      {mainImageToView.fileName}
                    </div>
                  ) : null}
                  {mainImageFailedToUploadMsg && (
                    <div className="errorFailedToUpload">
                      <p>{mainImageFailedToUploadMsg}</p>
                    </div>
                  )}
                </div>
                {mainImageToView && (
                  <div
                    className="btnChangeImage"
                    onClick={() =>
                      document.querySelector(`.input-field-main-image`).click()
                    }
                  >
                    <img
                      src={iconChangeImage}
                      alt="iconChangeImage"
                      width={17}
                      height={17}
                    ></img>
                    <div className="changeImageLabel">Change image</div>
                  </div>
                )}
                {mainImageToView ? null : (
                  <div className="labelImage">
                    {"Drag & drop your file(s) here"}
                  </div>
                )}
                {mainImageToView ? null : (
                  <div className="placeholderImage">
                    {"Recommended size is 1440 x 700 px"}
                  </div>
                )}
              </div>
              {mainImageEmpty ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : mainImageSizeTooBigMsg || mainImageFileTooLargeMsg ? (
                <div className="errorMessage">
                  <p>
                    {mainImageSizeTooBigMsg
                      ? mainImageSizeTooBigMsg
                      : mainImageFileTooLargeMsg}
                  </p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">What you will learn?</div>
              </div>
              <EditorText
                onChange={(content) =>
                  onInputChange(true, content, setWhatYouWillLearn)
                }
                preloadValue={whatYouWillLearn ? whatYouWillLearn : null}
              />
              {whatYouWillLearnEmpty ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            {scheduleCounter &&
              Array.isArray(scheduleCounter) &&
              scheduleCounter.length > 0 &&
              scheduleCounter.map((counter) => {
                return (
                  <InputSchedule
                    key={counter}
                    counter={counter}
                    firstInput={counter < 1 ? true : false}
                    addMoreScheduleInput={addMoreScheduleInput}
                    addMoreIcon={addMoreIcon}
                    WorkshopDropdownSelect={WorkshopDropdownSelect}
                    onDropdownScheduleChange={onDropdownScheduleChange}
                    schedules={schedules}
                    scheduleEmpty={scheduleEmpty}
                    onChangeInputDateSchedule={onChangeInputDateSchedule}
                    onInputChange={onInputChange}
                    handleClickMethodOfLearning={handleClickMethodOfLearning}
                  />
                );
              })}
            {facilitatorCounter &&
              Array.isArray(facilitatorCounter) &&
              facilitatorCounter.length > 0 &&
              facilitatorCounter.map((counter) => {
                return (
                  <InputFacilitator
                    key={counter}
                    counter={counter}
                    firstInput={counter < 1 ? true : false}
                    facilitators={facilitators}
                    addMoreFacilitatorInput={addMoreFacilitatorInput}
                    onInputChange={onInputChange}
                    addMoreIcon={addMoreIcon}
                    onImageChange={onImageChange}
                    uploadImageBtn={uploadImageBtn}
                    facilitatorEmpty={facilitatorEmpty}
                    iconChangeImage={iconChangeImage}
                  />
                );
              })}
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Target Audience</div>
              </div>
              <EditorText
                onChange={(content) =>
                  onInputChange(true, content, setTargetAudience)
                }
                preloadValue={targetAudience ? targetAudience : null}
              />
              {targetAudienceEmpty ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">How to register?</div>
              </div>
              <EditorText
                onChange={(content) =>
                  onInputChange(true, content, setHowToRegister)
                }
                preloadValue={howToRegister ? howToRegister : null}
              />
              {howToRegisterEmpty ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="labelForDescription">
                <div className="eachLabel">Registration link</div>
              </div>
              <textarea
                required
                name="registrationLink"
                type="text"
                className="xSmallInputBox"
                placeholder="Please input registration link here..."
                value={registrationLink}
                onChange={({ target: { value } }) =>
                  onInputChange(true, value, setRegistrationLink)
                }
              ></textarea>
              {registrationLinkEmpty ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostInput">
              <div className="eachLabel">Tags</div>
              <WorkshopDropdownSelect
                placeholder="Please input tags here..."
                options={tagOptions}
                selectStyle={{
                  borderRadius: "40px",
                  fontSize: "15px",
                  padding: "5px",
                  borderColor: "#0D4C86",
                }}
                optionStyle={{
                  backgroundColor: "#0D4C86",
                  fontSize: "15px",
                  color: "#FFFFFF",
                }}
                createAble={true}
                isMulti={true}
                onChange={(e) => onDropdownChange(e, setTags, true)}
                value={tags}
              />
              {tagEmpty ? (
                <div className="errorMessage">
                  <p>This field is required</p>
                </div>
              ) : null}
            </div>
            <div className="adminPostButtonContainer">
              <div>
                <span
                  className="postCancelButton btn"
                  onClick={() => navigate("/admin/isaAcademy-workshops")}
                >
                  Cancel
                </span>
              </div>
              <div>
                <button
                  className="postSaveButton btn"
                  onClick={(e) => checkAllInputs(e)}
                >
                  {isLoadingSave ? (
                    <span>
                      <Loader
                        type="spin"
                        color="black"
                        height="25px"
                        width="25px"
                      />
                    </span>
                  ) : (
                    <span>Save</span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default WorkshopForm;
