import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// CSS styling
import "../../styles/activitiesPublic.css";

// Axios and API
import { fetchActivities } from "../../api/fetchActivities";

// React Slideshow Image
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// Helpers
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter.js";

// Components
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import ContainerText from "../../components/Container";

const Activities = () => {
  const navigate = useNavigate();

  const mediaQueryMobile = window.matchMedia("(max-width: 500px)");
  const mediaQueryTab = window.matchMedia(
    "(min-width:641px) and (max-width: 820px)"
  );

  const [activities, setActivities] = useState([]);

  const fetchAllActivities = async () => {
    // setLoading(true);
    const response = await fetchActivities();
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      if (data && Array.isArray(data) && data.length > 0) {
        const arrActivities = data.sort(
          (a, b) => parseFloat(b.id) - parseFloat(a.id)
        );
        setActivities(arrActivities);
      }
    }
    // setLoading(false);
  };

  useEffect(() => {
    fetchAllActivities();
  }, []);

  const buttonStyle = {
    position: "absolute",
    zIndex: "1",
    cursor: "pointer",
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    color: "#000000",
    fontSize: "80px",
    padding: "20px",
    borderRadius: "50%",
  };

  const properties = {
    prevArrow: (
      <button className="arrow arrow-left" style={{ ...buttonStyle, left: 0 }}>
        <span>&lt;</span>
      </button>
    ),
    nextArrow: (
      <button
        className="arrow arrow-right"
        style={{ ...buttonStyle, right: 0 }}
      >
        <span>&gt;</span>
      </button>
    ),
  };

  return (
    <div id="activityPublic">
      <Header />
      <div className="activityPublicContainer">
        <ContainerText
          props={
            <div className="activityPublicCardContainer">
              <div className="activityTitle">
                <p>Activities</p>
              </div>
              <div className="activityPublicCards">
                {activities &&
                  Array.isArray(activities) &&
                  activities.length > 0 &&
                  activities.map((activity, index) => {
                    const {
                      id,
                      title,
                      activityType,
                      link,
                      ActivityImages,
                      ActivityTags,
                      input_summary,
                    } = activity || {};
                    return (
                      <div className="activityPublicEachCard" key={index}>
                        <div >
                          {ActivityImages &&
                          Array.isArray(ActivityImages) &&
                          ActivityImages.length > 1 ? (
                            <Slide {...properties}>
                              {ActivityImages.map((slideImage, idx) => {
                                const { img_url } = slideImage;
                                return (
                                  <div
                                    key={idx}
                                    className="activityPublicCardPicture"
                                  >
                                    <img
                                    key={idx}
                                      src={img_url}
                                      alt={`dummy slide ${idx + 1}`}
                                    />
                                  </div>
                                );
                              })}
                            </Slide>
                          ) : ActivityImages &&
                            Array.isArray(ActivityImages) &&
                            ActivityImages.length === 1 ? (
                            <div className="activityPublicCardPicture">
                              <img
                                src={ActivityImages[0]["img_url"]}
                                alt={`dummy slide`}
                              ></img>
                            </div>
                          ) : null}
                        </div>
                        <div
                          className="activityPublicCardTitle"
                          onClick={() => navigate(`/activities/${id}`)}
                        >
                          <p style={{ fontSize: "28px" }}>
                            {capitalizeFirstLetter(title)}
                          </p>
                        </div>
                        <div
                          className="activityPublicCardType"
                          style={{ lineHeight: "30px" }}
                        >
                          <p>{capitalizeFirstLetter(activityType)}</p>
                        </div>
                        <div
                          className="activityPublicCardDescription"
                          style={{ lineHeight: "30px" }}
                        >
                          <p>{input_summary}</p>
                        </div>
                        {ActivityTags &&
                        Array.isArray(ActivityTags) &&
                        ActivityTags.length > 0 ? (
                          <div style={{ marginTop: "20px" }}>
                            <div className="blogCardTags">Tags:</div>
                            {ActivityTags.map((tag, index) => {
                              const { Tag: { tag_name } = {} } = tag;
                              return (
                                <div
                                  className="blogCardTagsBoxContainer"
                                  key={index}
                                >
                                  <div className="blogCardTagsBox">
                                    <p>{capitalizeFirstLetter(tag_name)}</p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : null}
                        {link ? (
                          <div style={{ marginTop: "20px" }}>
                            <div className="blogCardTags">Link</div>
                            <div className="blogCardTagsBoxContainer">
                              <a
                                href={`https://${link}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {link}
                              </a>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
              </div>
            </div>
          }
        />
      </div>
      <Footer />
    </div>
  );
};

export default Activities;
