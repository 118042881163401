import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// CSS styling
import "../../styles/activitiesPublic.css";

// Axios and API
import { fetchActivityDetail } from "../../api/fetchActivityDetail";

// Moment.js
import moment from "moment";

// Helpers
import { capitalizeFirstLetter } from "../../helpers/capitalizeFirstLetter.js";

// Components
import Header from "../../components/Header.js";
import Footer from "../../components/Footer.js";
import ContainerText from "../../components/Container";

const ActivitiyDetails = () => {
  const { activityId } = useParams();
  const navigate = useNavigate();

  const [activity, setActivity] = useState(null);
  const [fullScreenImage, setFullScreenImage] = useState(null);

  useEffect(() => {
    if (activityId) {
      fetchOneActivity(activityId);
    }
  }, [activityId]);

  const fetchOneActivity = async (activityId) => {
    const response = await fetchActivityDetail(activityId);
    if (response && response.status === 200 && response.data) {
      const { data = {} } = response;
      setActivity(data);
    }
  };

  // click to view image full screen
  const openFullScreen = (imageUrl) => {
    setFullScreenImage(imageUrl);
  };

  // click to close image full screen
  const closeFullScreen = () => {
    setFullScreenImage(null);
  };

  return (
    <div id="activityPublic">
      <Header />
      {activity ? (
        <div className="activityPublicContainer">
          <ContainerText
            props={
              <div className="activityPublicDetailCardContainerDesc">
                <div
                  className="previousButton"
                  onClick={() => navigate("/activities")}
                  style={{ marginTop: "4em" }}
                >
                  <span className="expertiseArrowRight">
                    <i className="fas fa-angle-left" />
                  </span>
                  <a href="">Back to previous page</a>
                </div>
                {activity.title && activity.activityDate ? (
                  <div className="activityPublicCardTitle">
                    <div className="activityPublicCardDescription">
                      {moment(activity.activityDate).format("DD/MM/YYYY")}
                    </div>
                    <p style={{ fontSize: "28px" }}>
                      {capitalizeFirstLetter(activity.title)}
                    </p>
                  </div>
                ) : null}
                {activity.activityType ? (
                  <div
                    className="activityPublicCardType"
                    style={{ lineHeight: "30px" }}
                  >
                    <p>{capitalizeFirstLetter(activity.activityType)}</p>
                  </div>
                ) : null}
                {activity.description ? (
                  <div
                    className="activityPublicCardDescription"
                    style={{ lineHeight: "30px" }}
                  >
                    <p>{activity.description}</p>
                  </div>
                ) : null}
              </div>
            }
          />
          <ContainerText
            props={
              <div className="activityPublicDetailCardContainer">
                <div className="activityPublicCards">
                  <div className="activityPublicEachCard">
                    {activity &&
                    activity.ActivityTags &&
                    Array.isArray(activity.ActivityTags) &&
                    activity.ActivityTags.length > 0 ? (
                      <div style={{ marginTop: "20px" }}>
                        <div className="blogCardTags">Tags:</div>
                        {activity.ActivityTags.map((tag, index) => {
                          const { Tag: { tag_name } = {} } = tag;
                          return (
                            <div
                              className="blogCardTagsBoxContainer"
                              key={index}
                            >
                              <div className="blogCardTagsBox">
                                <p>{capitalizeFirstLetter(tag_name)}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                  <div className="activityPublicEachCard">
                    {activity && activity.link ? (
                      <div style={{ marginTop: "20px" }}>
                        <div className="blogCardTags">Link</div>
                        <div className="blogCardTagsBoxContainer">
                          <a
                            href={`https://${activity.link}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {activity.link}
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            }
          />
          <ContainerText
            props={
              <div className="activityPublicCardContainer">
                <div className="activityPublicCards">
                  {activity &&
                    activity.ActivityImages &&
                    Array.isArray(activity.ActivityImages) &&
                    activity.ActivityImages.length > 0 &&
                    activity.ActivityImages.map((activityImages, index) => {
                      const { img_url } = activityImages || {};
                      return (
                        <div key={index} className="activityPublicEachCard">
                          <div className="activityPublicCardPicture">
                            <img
                              src={img_url}
                              alt={`Slide ${index + 1}`}
                              onClick={() => openFullScreen(img_url)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {fullScreenImage && (
                    <div
                      className="image-viewer full-screen"
                      onClick={closeFullScreen}
                      onKeyDown={closeFullScreen}
                      tabIndex={0}
                    >
                      <div className="image-container">
                        <img src={fullScreenImage} alt="Full-Screen Image" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            }
          />
        </div>
      ) : null}
      <Footer />
    </div>
  );
};

export default ActivitiyDetails;
